import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import styled from "styled-components"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

const HeaderSection = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: auto;
  flex-flow: row;
  background-color: rgba(30, 139, 195, 1);
`

const HeaderText = styled.div`
  border: 0px solid green;
  font-family: montserrat;
  width: 100%;
`
const List = styled.ul`
  margin: 0;
  padding: 1rem;
  color: white;
  background-color: (30, 139, 195, 1);
`

const BreadCrumb = styled.li`
  display: inline;
  padding: 1rem;
`
const BreadCrumbLink = styled(Link)`
  color: white;
  text-align: center;
  padding: 24px 16px;
  color: #ccc;
  text-decoration: none;
`
const PanelRow = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-flow: column;
    padding: 1rem;
  }
  @media (min-width: 768px) {
    flex-flow: row;
    padding: 5rem;
  }
`

const ContactContainer = styled.div`
  border-radius: 5px;
  background-color: #f2f2f2;
  width: 100%;
  color: black;
  @media (max-width: 768px) {
    padding: 10px;
  }
  @media (min-width: 768px) {
    padding: 20px;
  }
`

const IndexPage = () => (
  <Layout>
    <HeaderSection>
      <HeaderText>
        <List>
          <BreadCrumb>
            <span>
              <BreadCrumbLink to="/">Home </BreadCrumbLink>
            </span>

            <span>Privacy</span>
          </BreadCrumb>
        </List>
      </HeaderText>
    </HeaderSection>
    <div
      style={{
        color: "black",
        paddingLeft: "4rem",
        paddingRight: "4rem",
      }}
    >
      <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "2rem" }}>
        {" "}
        Inflowkit Privacy Policy
      </p>
      <p>
        This Privacy Policy describes how your personal information is
        collected, used, and shared when you visit or make a purchase from
        www.inflowkit.com (the “Site”).
      </p>

      <p>
        PERSONAL INFORMATION WE COLLECT When you visit the Site, we
        automatically collect certain information about your device, including
        information about your web browser, IP address, time zone, and some of
        the cookies that are installed on your device. Additionally, as you
        browse the Site, we collect information about the individual web pages
        or products that you view, what websites or search terms referred you to
        the Site, and information about how you interact with the Site. We refer
        to this automatically-collected information as “Device Information.” We
        collect Device Information using the following technologies: - “Cookies”
        are data files that are placed on your device or computer and often
        include an anonymous unique identifier. For more information about
        cookies, and how to disable cookies, visit
        http://www.allaboutcookies.org. - “Log files” track actions occurring on
        the Site, and collect data including your IP address, browser type,
        Internet service provider, referring/exit pages, and date/time stamps. -
        “Web beacons,” “tags,” and “pixels” are electronic files used to record
        information about how you browse the Site. Additionally when you make a
        purchase or attempt to make a purchase through the Site, we collect
        certain information from you, including your name, billing address,
        shipping address, payment information (including credit card numbers),
        email address, and phone number. We refer to this information as “Order
        Information.”When we talk about “Personal Information” in this Privacy
        Policy, we are talking both about Device Information and Order
        Information.
      </p>
      <p>
        HOW DO WE USE YOUR PERSONAL INFORMATION? We use the Order Information
        that we collect generally to fulfill any orders placed through the Site
        (including processing your payment information, arranging for shipping,
        and providing you with invoices and/or order confirmations).
        Additionally, we use this Order Information to: Communicate with you;
        Screen our orders for potential risk or fraud; and When in line with the
        preferences you have shared with us, provide you with information or
        advertising relating to our products or services. We use the Device
        Information that we collect to help us screen for potential risk and
        fraud (in particular, your IP address), and more generally to improve
        and optimize our Site (for example, by generating analytics about how
        our customers browse and interact with the Site, and to assess the
        success of our marketing and advertising campaigns). We share your
        Personal Information with third parties to help us use your Personal
        Information, as described above. We use Google Analytics to help us
        understand how our customers use the Site--you can read more about how
        Google uses your Personal Information here:
        https://www.google.com/intl/en/policies/privacy/. You can also opt-out
        of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
        Finally, we may also share your Personal Information to comply with
        applicable laws and regulations, to respond to a subpoena, search
        warrant or other lawful request for information we receive, or to
        otherwise protect our rights. As described above, we use your Personal
        Information to provide you with targeted advertisements or marketing
        communications we believe may be of interest to you. For more
        information about how targeted advertising works, you can visit the
        Network Advertising Initiative’s (“NAI”) educational page at
        http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
        You can opt out of targeted advertising by: visiting
        https://www.facebook.com/settings/?tab=ads
        https://www.google.com/settings/ads/anonymous Additionally, you can opt
        out of some of these services by visiting the Digital Advertising
        Alliance’s opt-out portal at: http://optout.aboutads.info/. DO NOT TRACK
        Please note that we do not alter our Site’s data collection and use
        practices when we see a Do Not Track signal from your browser.
      </p>
      <p>
        YOUR RIGHTS If you are a European resident, you have the right to access
        personal information we hold about you and to ask that your personal
        information be corrected, updated, or deleted. If you would like to
        exercise this right, please contact us through the contact information
        below. Additionally, if you are a European resident we note that we are
        processing your information in order to fulfill contracts we might have
        with you (for example if you make an order through the Site), or
        otherwise to pursue our legitimate business interests listed above.
        Additionally, please note that your information will be transferred
        outside of Europe, including to Canada and the United States.
      </p>
      <p>
        DATA RETENTION When you place an order through the Site, we will
        maintain your Order Information for our records unless and until you ask
        us to delete this information.
      </p>
      <p>
        MINORS The Site is not intended for individuals under the age of 13.
      </p>
      <p>
        CHANGES We may update this privacy policy from time to time in order to
        reflect, for example, changes to our practices or for other operational,
        legal or regulatory reasons. CONTACT US For more information about our
        privacy practices, if you have questions, or if you would like to make a
        complaint, please contact us by e-mail at contact@inflowkit.com or by mail
        using the details provided below: P.O Box 9921, Nairobi, 110, 00200,
        Kenya
      </p>
    </div>
  </Layout>
)

export default IndexPage
